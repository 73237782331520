export const DEFAULT_STALE_TIME = 60000;

export const paramsToString = (filters) => {
  // encode every parameter (unpack list into 2 variables)
  let query = Object.entries(filters)
    .map(([k, v]) => {
      if (Array.isArray(v)) return [k, v.join(",")];
      return [k, v];
    })
    .filter(([_, v]) => v)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join("&");

  return query;
};

export const consineSimilarity = (A, B) => {
  var dotproduct = 0;
  var mA = 0;
  var mB = 0;
  for (let i = 0; i < A.length; i++) {
    // here you missed the i++
    dotproduct += A[i] * B[i];
    mA += A[i] * A[i];
    mB += B[i] * B[i];
  }
  mA = Math.sqrt(mA);
  mB = Math.sqrt(mB);
  var similarity = dotproduct / (mA * mB); // here you needed extra brackets
  return similarity;
};

export const calcGrade = (v1, v2) => {
  const cosine = consineSimilarity(v1, v2);
  var grade = parseInt((cosine + 0.35) * 100);
  grade = Math.min(grade, 100);
  grade = Math.max(grade, 0);
  return grade;
};

export const compageFaces = (face1, face2) => {
  const v1 = face1.embedded[0] || face1.embedded_192[0];
  const v2 = face2.embedded[0] || face2.embedded_192[0];
  const cosine = consineSimilarity(v1, v2);
  var grade = parseInt((cosine + 0.35) * 100);
  grade = Math.min(grade, 100);
  grade = Math.max(grade, 0);
  return grade;
};

export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const getThresholdDate = (dateThreshold) => {
  var newDate = new Date();
  if (dateThreshold !== 0) {
    newDate.setDate(newDate.getDate() - dateThreshold);
  }
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const dateDisplay = (date) => {
  return `${date.toLocaleTimeString()}, ${date.toLocaleDateString()}`;
};

export const isMobileDevice = () => {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isRecognized = (face) => {
  return face.person_id !== "N/A" && face.person_id;
};

export const isWeakPassword = (password) => {
  if (!password.match(/[a-z]+/)) {
    return true;
  }
  if (!password.match(/[A-Z]+/)) {
    return true;
  }
  if (!password.match(/[0-9]+/)) {
    return true;
  }

  if (password.length < 8) {
    return true;
  }

  return false;
};

export const buildFiltersQuery = (filtersToUse, persons) => {
  var personsIds = "";
  if (filtersToUse.name && filtersToUse.name !== "null") {
    personsIds = persons
      .filter((p) =>
        p.person_name
          .toLowerCase()
          .replaceAll("_", " ")
          .includes(filtersToUse.name.toLowerCase())
      )
      .map((p) => p.id)
      .join(",");
  }

  var nonEmptyFilters = {};
  if (personsIds) {
    nonEmptyFilters.predicted_id = personsIds;
  } else if (filtersToUse.name && filtersToUse.name !== "null" && !personsIds) {
    console.log("No person match this search");
    nonEmptyFilters.predicted_id = "id_not_found";
  }

  if (filtersToUse.group_id && filtersToUse.group_id !== "null") {
    nonEmptyFilters.group_id = filtersToUse.group_id;
  }
  if (filtersToUse.camera_id && filtersToUse.camera_id !== "null") {
    nonEmptyFilters.camera_id = filtersToUse.camera_id;
  }

  if (filtersToUse.identification_only === "true") {
    nonEmptyFilters.is_recognized = true;
  }

  if (filtersToUse.first_seen && filtersToUse.first_seen === "true") {
    nonEmptyFilters.first_seen = true;
  }
  // if (filtersToUse.involuntary && filtersToUse.involuntary === "true") {
  //   nonEmptyFilters.involuntary = true;
  // }
  // else {
  //   nonEmptyFilters.involuntary = false;
  // }

  if (filtersToUse.create_at_after && filtersToUse.create_at_after !== "null") {
    nonEmptyFilters.create_at_after = new Date(
      filtersToUse.create_at_after
    ).toISOString();
  }
  if (
    filtersToUse.create_at_before &&
    filtersToUse.create_at_before !== "null"
  ) {
    nonEmptyFilters.create_at_before = new Date(
      filtersToUse.create_at_before
    ).toISOString();
  }
  if (filtersToUse.conf && filtersToUse.conf !== "null") {
    nonEmptyFilters.grade_min = filtersToUse.conf;
  }
  if (filtersToUse.spoof && filtersToUse.spoof !== "null") {
    nonEmptyFilters.spoof = filtersToUse.spoof;
  }
  if (filtersToUse.user_action && filtersToUse.user_action !== "null") {
    nonEmptyFilters.user_action = filtersToUse.user_action;
  }
  // Do not include in the response the manually uploaded and the api predictions
  if (filtersToUse?.camera_id?.includes("api_predicted")) {
    nonEmptyFilters.events_only = false;
  } else {
    nonEmptyFilters.events_only = true;
  }

  return nonEmptyFilters;
};

export const bitAfterNow = () => parseInt(Date.now() / 1000) + 100;

export function getQueryParams(url) {
  return url
    ? Object.fromEntries(new URLSearchParams(new URL(url).search)) || {}
    : {};
}

export const removeNullKeys = (obj) => {
  if (!obj) return obj;
  // remove all keys with null values recursively
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeNullKeys(obj[key]);
    else if (obj[key] === null) delete obj[key];
  });

  return obj;
};

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
