import React, { useEffect } from "react";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import TooltipSr from "../general/TooltipSr";

function CameraSettingsPersonTracking(props) {
  const { camera, setCamera } = props;
  const personTrackingSettings = camera?.person_tracking || {};
  const ppeDetectionSettings = camera?.ppe_detection || {};

  const changePersonTrackingValue = (key, value) => {
    var tempSettings = { ...camera.person_tracking };
    tempSettings[key] = value;
    setCamera({ ...camera, person_tracking: tempSettings });
  };

  const changePPEDetectionValue = (key, value) => {
    var tempSettings = { ...camera.ppe_detection };
    tempSettings[key] = value;
    setCamera({ ...camera, ppe_detection: tempSettings });
  };

  useEffect(() => {
    if (!camera || !setCamera) return;
    if (!camera?.person_tracking) {
      setCamera({
        ...camera,
        person_tracking: {
          enabled: false,
        },
      });
    }
    if (!camera?.ppe_detection) {
      setCamera({
        ...camera,
        ppe_detection: {
          enabled: false,
          threshold: 0.7,
          min_reoccurrence: 3,
        },
      });
    }
  }, [camera, setCamera]);

  return (
    <div
      className="d-flex flex-column py-2 gap-8"
      style={{
        fontSize: "14px",
      }}
    >
      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className="text-truncate fw-bolder"
          title="Enable person tracking"
          style={{
            maxWidth: "200px",
            width: "100%",
          }}
        >
          Enable person tracking
        </div>

        <SwitchCheckbox
          checked={personTrackingSettings.enabled}
          onChange={(e) =>
            changePersonTrackingValue("enabled", e.target.checked)
          }
        />
      </div>
      {camera?.person_tracking?.enabled && (
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className="text-truncate fw-bolder"
            title="Enable PPE detection"
            style={{
              maxWidth: "200px",
              width: "100%",
            }}
          >
            Enable PPE detection
          </div>

          <SwitchCheckbox
            checked={ppeDetectionSettings.enabled}
            onChange={(e) =>
              changePPEDetectionValue("enabled", e.target.checked)
            }
          />
        </div>
      )}

      {camera?.person_tracking?.enabled && camera?.ppe_detection?.enabled && (
        <>
          <div className="d-flex align-items-center justify-content-start gap-8">
            <div
              className="text-truncate fw-bolder"
              title="PPE detection threshold"
              style={{
                maxWidth: "200px",
                width: "100%",
              }}
            >
              PPE detection threshold
            </div>
            <input
              className="sr-text-input"
              style={{ width: "50px" }}
              type="number"
              placeholder="0.7"
              value={camera?.ppe_detection?.threshold || 0.7}
              onChange={(e) =>
                changePPEDetectionValue("threshold", parseFloat(e.target.value))
              }
            />
          </div>
          <TooltipSr title="Minimum frames with detections to ensure PPE">
            <div className="d-flex align-items-center justify-content-start gap-8">
              <div
                className="text-truncate fw-bolder"
                title="PPE minimum reoccurrence"
                style={{
                  maxWidth: "200px",
                  width: "100%",
                }}
              >
                PPE minimum reoccurrence
              </div>
              <input
                className="sr-text-input"
                style={{ width: "50px" }}
                type="number"
                placeholder="3"
                value={camera?.ppe_detection?.min_reoccurrence || 3}
                onChange={(e) =>
                  changePPEDetectionValue(
                    "min_reoccurrence",
                    parseInt(e.target.value)
                  )
                }
              />
            </div>
          </TooltipSr>
        </>
      )}
    </div>
  );
}

export default CameraSettingsPersonTracking;
