import React, { useContext, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { COLORS } from "../../assets/colors";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { DataContext } from "../../App";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import {
  FaChevronLeft,
  FaChevronRight,
  FaExclamationTriangle,
  FaPencilAlt,
  FaSpinner,
  FaTrashAlt,
} from "react-icons/fa";
import Swal from "sweetalert2/src/sweetalert2.js";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { Modal } from "react-bootstrap";
import PeopleCreateGroupPopup from "./PeopleCreateGroupPopup";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiDeleteGroup } from "../../context/groupContext";
import { useIsMobile } from "../../utils/hooks";

function GroupCard(props) {
  const { group } = props;
  const { userInfo } = useContext(DataContext);
  const isMobile = useIsMobile();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();
  const { mutate: deleteGroup } = useApiDeleteGroup();

  const [showEditGroupModal, setShowEditGroupModal] = useState(false);

  const groupPersons = persons.filter((p) => p.group === group.id);

  const handleDeleteGroup = () => {
    deleteGroup({
      groupId: group.id,
      onSuccessCallback: () => showSuccessDialog("Group deleted successfully"),
      onErrorCallback: () => showErrorDialog("Failed to delete group"),
    });
  };

  const beforeDeleteGroup = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      html: ReactDOMServer.renderToString(
        <div>
          <div>
            You are about to delete the "{group.name}" group{" "}
            {groupPersons.length > 0 && (
              <>
                along with the
                {" " + groupPersons.length} people in it
              </>
            )}
          </div>
          <div className="d-flex my-2 justify-content-center">
            {groupPersons.slice(0, 3).map((person) => (
              <div
                className="mx-2"
                key={`delete-person-${person.id}`}
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: COLORS.FontGray,
                }}
              >
                <img
                  src={`${IMAGE_BASE_PATH}${person.faces[0]}`}
                  alt={`delete-person-${person.id}`}
                  height="50px"
                  width="50px"
                  style={{
                    borderRadius: "50%",
                  }}
                />
              </div>
            ))}
            {groupPersons.length > 3 && (
              <span className="align-self-end" style={{ fontSize: "12px" }}>
                and more...
              </span>
            )}
          </div>

          <div>You won't be able to revert this.</div>
        </div>
      ),
    }).then((result) => {
      if (result.value) {
        handleDeleteGroup();
      }
    });
  };

  const properties = {
    duration: 5000,
    slidesToShow: 2, // calculate how many pics we can put in the screen at the same time
    slidesToScroll: 2,
    infinite: false,
    indicators: groupPersons.length > 2,
    arrows: groupPersons.length > 2,
    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 10,
          indicators: groupPersons.length > 10,
          arrows: groupPersons.length > 10,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
          indicators: groupPersons.length > 8,
          arrows: groupPersons.length > 8,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          indicators: groupPersons.length > 3,
          arrows: groupPersons.length > 3,
        },
      },
    ],
    autoplay: false,
    prevArrow: (
      <FaChevronLeft
        className="box-shadow-hover"
        style={{ cursor: "pointer", borderRadius: "12px" }}
      />
    ),
    nextArrow: (
      <FaChevronRight
        className="box-shadow-hover"
        style={{ cursor: "pointer", borderRadius: "12px" }}
      />
    ),
  };

  return (
    <div className="my-3">
      <div
        className="p-2"
        style={{
          border: "solid 1px",
          borderRadius: "8px",
          borderColor: COLORS.Gray100,
          color: COLORS.FontGray,
          backgroundColor: COLORS.Gray800,
        }}
      >
        <Modal
          show={showEditGroupModal}
          onHide={() => setShowEditGroupModal(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          fullscreen={"md-down"}
        >
          <PeopleCreateGroupPopup
            close={() => setShowEditGroupModal(false)}
            group={group}
          />
        </Modal>
        <div className="d-flex align-items-center">
          <div
            className="me-2"
            style={{
              height: "24px",
              width: "10px",
              backgroundColor: COLORS.GROUP[group.color],
            }}
          ></div>
          <div className="fs-5 text-uppercase">{group.name}</div>
        </div>
        <div
          className={`d-flex  gap-8 ${
            isMobile ? "flex-column align-items-start" : "align-items-center"
          }`}
        >
          <div className="d-flex align-items-center gap-2">
            <span className="text-truncate">People in the group:</span>
            <span className="fw-bold">{groupPersons.length}</span>
          </div>
          {!isMobile && (
            <div
              style={{
                height: "24px",
                borderRight: "solid 1px",
              }}
            />
          )}
          <div className="d-flex align-items-center gap-2">
            <span
              className="text-truncate "
              style={{
                maxWidth: "75%",
              }}
              title="Minimum confidence for recognition"
            >
              Minimum confidence for recognition:
            </span>
            <span className="fw-bold"> {group.conf}%</span>
          </div>

          <div className="col text-center">
            <button
              className="sr-outline-btn mx-2"
              onClick={() => setShowEditGroupModal(true)}
              disabled={!userInfo?.is_staff}
            >
              <FaPencilAlt className="me-2" /> Edit
            </button>
            <button
              className="sr-outline-btn sr-outline-btn-danger mx-2"
              onClick={beforeDeleteGroup}
              disabled={!userInfo?.is_staff}
            >
              <FaTrashAlt className="me-2" /> Delete
            </button>
          </div>
        </div>
        {isFetchingPersons ? (
          <div className="my-4 text-center ">
            <FaSpinner className="fa-spin fs-3" />
          </div>
        ) : (
          <div className="mt-4 mx-2 ">
            {groupPersons.length === 0 ? (
              <div className="my-2 d-flex align-items-center justify-content-center">
                <FaExclamationTriangle className="me-2" />
                There are no people in this group
              </div>
            ) : (
              <Slide {...properties}>
                {groupPersons.slice(0, 100).map((person) => (
                  <div
                    key={`group-person-${person.id}`}
                    style={{
                      textAlign: "center",
                      // background: "teal",
                      //   padding: "20px 10px",
                      fontSize: "16px",
                      color: COLORS.FontGray,
                    }}
                  >
                    <img
                      src={`${IMAGE_BASE_PATH}${person.faces[0]}`}
                      alt={`group-person-${person.id}`}
                      height="100px"
                      width="100px"
                      style={{
                        borderRadius: "6px",
                      }}
                    />
                    <div className="text-truncate" style={{ fontSize: "12px" }}>
                      {person.person_name}
                    </div>
                  </div>
                ))}
              </Slide>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default GroupCard;
