import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteItem, get, post, put } from "../faceit-api/FaceitApi";
import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";

export const PEOPLE_MAX_PAGE = 20000;

async function getPersons() {
  return get(
    `persons?${new URLSearchParams({ page_size: PEOPLE_MAX_PAGE }).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}

async function updatePerson({ personId, personData }) {
  return put(`persons/${personId}`, personData);
}

async function createPerson({ personData }) {
  return post(`persons`, personData);
}

async function deletePerson({ personId }) {
  return deleteItem(`persons/${personId}`);
}

async function createBulkPersons({ personsData }) {
  return post(`persons/bulk`, personsData);
}

async function addFacesPerson({ personId, personData }) {
  return put(`persons/${personId}/faces`, personData);
}

export const useApiPersonsData = () => {
  return useQuery("getPersons", getPersons, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

export const useApiUpdatePersonInContext = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ person }) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(person);
        }, 50);
      }),
    {
      onSuccess: (person) => {
        queryClient.setQueryData("getPersons", (persons) => {
          if (!persons) return [];
          var tempPersons = [...persons];
          const personIndex = persons.findIndex((p) => p.id === person.id);
          tempPersons[personIndex] = person;
          return tempPersons;
        });
      },
    }
  );
};

export const useApiUpdatePerson = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePerson, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        const newPerson = resp;
        queryClient.setQueryData("getPersons", (oldPersons) => {
          let index = oldPersons.findIndex((p) => p.id === newPerson.id);
          let newPersons = [...oldPersons];
          newPersons[index] = newPerson;
          return newPersons;
        });
        return onSuccessCallback(newPerson);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiCreateBulkPersons = () => {
  const queryClient = useQueryClient();
  return useMutation(createBulkPersons, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getPersons", (oldPersons) => [
          ...(oldPersons || []),
          ...resp.persons,
        ]);
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiAddPersonFaces = () => {
  const queryClient = useQueryClient();
  return useMutation(addFacesPerson, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getPersons", (oldPersons) => {
          let index = oldPersons.findIndex((p) => p.id === resp.id);
          let newPersons = [...oldPersons];
          newPersons[index] = resp;
          return newPersons;
        });
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiCreatePerson = () => {
  const queryClient = useQueryClient();
  return useMutation(createPerson, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getPersons", (oldPersons) => [
          ...oldPersons,
          resp,
        ]);
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiDeletePerson = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePerson, {
    onSuccess: (resp, { personId, onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getPersons", (oldPersons) =>
          oldPersons.filter((p) => p.id !== personId)
        );
        return onSuccessCallback();
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};
