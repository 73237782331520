import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, post, put } from "../faceit-api/FaceitApi";
import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import { PEOPLE_MAX_PAGE } from "./personsContext";

async function getCameras() {
  return get(
    `cameras?${new URLSearchParams({ page_size: PEOPLE_MAX_PAGE }).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}

async function updateCamera({ cameraId, cameraData }) {
  return put(`cameras/${cameraId}`, cameraData);
}

async function createCamera({ cameraData }) {
  return post(`cameras`, cameraData);
}

export const useApiCamerasData = () => {
  return useQuery("getCameras", getCameras, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

export const useApiUpdateCameraInContext = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ camera }) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(camera);
        }, 50);
      }),
    {
      onSuccess: (camera) => {
        queryClient.setQueryData("getCameras", (cameras) => {
          if (!cameras) return [];
          var tempCameras = [...cameras];
          const cameraIndex = cameras.findIndex((p) => p.id === camera.id);
          tempCameras[cameraIndex] = camera;
          return tempCameras;
        });
      },
    }
  );
};

export const useApiUpdateCamera = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCamera, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        const newCamera = resp;
        queryClient.setQueryData("getCameras", (oldCameras) => {
          let index = oldCameras.findIndex((p) => p.id === newCamera.id);
          let newCameras = [...oldCameras];
          newCameras[index] = newCamera;
          return newCameras;
        });
        return onSuccessCallback(newCamera);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiCreateCamera = () => {
  const queryClient = useQueryClient();
  return useMutation(createCamera, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getCameras", (oldCameras) => [
          ...oldCameras,
          resp,
        ]);
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};
