import styled from "styled-components";
import { BaseButton } from "./BaseButton";
import { COLORS } from "../../../assets/colors";

export const SecondaryButton = styled(BaseButton)`
    background: ${COLORS.Gray800};
    border: 1px solid ${COLORS.FontGray};
    &:hover {
        border: 1px solid ${COLORS.PrimaryLight};
    }
`;
