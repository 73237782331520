import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  FaCheck,
  FaSortAmountUpAlt,
  FaSpinner,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import {
  useApiDeleteTagEvent,
  useApiTagEventsFilteredPagination,
} from "../../context/tagsContext";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import IconUser from "../../assets/icons/user.svg";
import Swal from "sweetalert2/src/sweetalert2.js";
import ReactDOMServer from "react-dom/server";
import MainContainer from "../main-menu/MainContainer";
import TooltipSr from "../general/TooltipSr";
import { useApiPersonsData } from "../../context/personsContext";
import {
  showErrorDialog,
  showInfoDialog,
  showSuccessDialog,
} from "../../popups/opretaions";
import { Box, CircularProgress } from "@mui/material";
import TagsFilter from "./TagsFilter";
import { Modal } from "react-bootstrap";
import PersonModal from "../face/PersonModal";

const MIN_PAGE_SIZE = 25;

function Tags() {
  const { data: cameras } = useApiCamerasData();
  const { data: persons } = useApiPersonsData();
  const { mutate: deleteTagEvent, isLoading: isDeleting } =
    useApiDeleteTagEvent();

  const [tags, setTags] = useState([]);
  const [paginationPerPage, setPaginationPerPage] = useState(MIN_PAGE_SIZE);
  const [ordering, setOrdering] = useState("-created");
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(false);

  const { data: tagsData, isFetching } = useApiTagEventsFilteredPagination(
    paginationPerPage,
    ordering,
    page,
    filters
  );

  useEffect(() => {
    setTags(tagsData?.results || []);
    setTotalRows(tagsData?.count || 0);
  }, [tagsData]);

  const handleTagClicked = (row) => {
    const person = persons?.find((p) => p.id === row.person_id);
    if (!!person) {
      setSelectedPerson(person);
      setShowPersonModal(true);
    } else {
      showInfoDialog("There is no person associated with this tag event");
    }
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: COLORS.Gray50,
        secondary: COLORS.Gray100,
      },
      background: {
        default: COLORS.BgBlack,
      },
      context: {
        background: "#cb4b16",
        text: COLORS.Gray50,
      },
      divider: {
        default: COLORS.Gray50,
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: COLORS.BgBlack,
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const findCamera = (cameraId) => {
    return cameras?.find((cam) => cam.id === cameraId) || {};
  };

  const beforeDeleteEvent = (event) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      html: ReactDOMServer.renderToString(
        <div>
          <div>You are about to delete tag event.</div>

          <div>You won't be able to revert this.</div>
        </div>
      ),
    }).then((result) => {
      if (result.value) {
        handleDeleteEvent(event);
      }
    });
  };

  const handleDeleteEvent = (event) => {
    deleteTagEvent({
      tagEventId: event.id,
      onSuccessCallback: () =>
        showSuccessDialog("Tag event deleted successfully"),
      onErrorCallback: () => showErrorDialog("Failed to delete tag event"),
    });
  };

  const columns = [
    {
      name: "Capture face",
      id: "image",
      selector: (row) => row.image,
      sortable: false,
      cell: (row) => (
        <div className="py-3 pointer">
          <img
            className="sr-image-person"
            style={{
              borderRadius: "50%",
            }}
            src={row.image ? `${IMAGE_BASE_PATH}/${row.image}` : IconUser}
            alt="tag-event"
          />
        </div>
      ),
    },
    {
      name: "Tag ID",
      id: "tag_id",
      selector: (row) => row.tag_id,
      sortable: false,
      cell: (row) => (
        <div style={{ display: "contents" }}>
          <TooltipSr placement="top" title={row.tag_id}>
            <span className="text-truncate fw-bold">{row.tag_id}</span>
          </TooltipSr>
        </div>
      ),
    },
    {
      name: "Person",
      id: "person_id",
      selector: (row) => row.person_id,
      sortable: false,
      cell: (row) => {
        const person = persons?.find((p) => p.id === row.person_id) || {
          person_name: "N/A",
        };
        return (
          <div style={{ display: "contents", backgroundColor: "green" }}>
            <TooltipSr placement="top" title={person?.person_name}>
              <span className="text-truncate fw-bold">
                {person?.person_name}
              </span>
            </TooltipSr>
          </div>
        );
      },
    },
    {
      name: "Camera",
      id: "camera_id",
      sortable: true,
      selector: (row) =>
        row.camera_id ? findCamera(row.camera_id).name : "N/A",
      cell: (row) => {
        const camera = row?.camera_id
          ? findCamera(row.camera_id)
          : { name: "N/A" };
        return (
          <div style={{ display: "contents" }}>
            <TooltipSr placement="top" title={camera?.name}>
              <span className="text-truncate fw-bold">{camera?.name}</span>
            </TooltipSr>
          </div>
        );
      },
    },
    {
      name: "Result",
      id: "result",
      selector: (row) => row.result,
      sortable: false,
      cell: (row) => (
        <div style={{ display: "contents" }}>
          <TooltipSr placement="top" title={row.result}>
            <span className="text-truncate fw-bold">{row.result}</span>
          </TooltipSr>
        </div>
      ),
    },
    {
      name: "Notification type",
      id: "type",
      selector: (row) => row.type,
      sortable: false,
      cell: (row) => (
        <div style={{ display: "contents" }}>
          <TooltipSr placement="top" title={row.type}>
            <span className="text-truncate fw-bold">
              {row.type?.replaceAll("_", " ")}
            </span>
          </TooltipSr>
        </div>
      ),
    },
    {
      name: "Notification sent",
      id: "sent",
      selector: (row) => row.sent,
      sortable: false,
      cell: (row) => (
        <div className="d-flex w-100 justify-content-center">
          <TooltipSr placement="top" title={row.sent}>
            <span className="text-truncate fw-bold text-center">
              {row.sent ? <FaCheck /> : <FaTimes />}
            </span>
          </TooltipSr>
        </div>
      ),
    },
    {
      name: "Created",
      selector: (row) => row.created,
      id: "created",
      sortable: true,
      cell: (row) => (
        <TooltipSr
          placement="top"
          title={new Date(row["created"]).toLocaleString()}
        >
          <div>{new Date(row["created"]).toLocaleString()}</div>
        </TooltipSr>
      ),
    },
    {
      name: "Actions",
      id: "actions",
      selector: (row) => row.date_joined,
      sortable: false,
      cell: (row) =>
        row.is_superuser ? (
          <></>
        ) : (
          <div className="d-flex align-items-center">
            {isDeleting ? (
              <FaSpinner className="fa-spin" />
            ) : (
              <FaTrash
                color={COLORS.GROUP.LIGHT.red}
                className="pointer fs-6 "
                onClick={() => beforeDeleteEvent(row)}
              />
            )}
          </div>
        ),
    },
  ];

  return (
    <MainContainer>
      <div className="fs-4">Tag events</div>
      <TagsFilter setFilters={setFilters} />
      {isFetching && (
        <Box className="text-center fs-4 my-2">
          <CircularProgress color="primary" />
        </Box>
      )}

      <Modal
        show={showPersonModal}
        onHide={() => setShowPersonModal(false)}
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <PersonModal
          close={() => setShowPersonModal(false)}
          person={selectedPerson || {}}
          setPerson={setSelectedPerson}
        />
      </Modal>

      <div className="mt-5">
        <DataTable
          key={tags?.length}
          columns={columns}
          data={tags}
          pagination
          responsive={true}
          paginationServer={true}
          highlightOnHover
          paginationPerPage={paginationPerPage}
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={[MIN_PAGE_SIZE, 50, 100]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
            setPaginationPerPage(currentRowsPerPage)
          }
          onChangePage={(currentPage) => {
            setPage(currentPage);
          }}
          sortIcon={<FaSortAmountUpAlt />}
          noDataComponent={
            isFetching ? "Loading events..." : "No tag events found"
          }
          theme="solarized"
          customStyles={{ rows: { style: { cursor: "pointer" } } }}
          sortServer
          sortFunction={(column, sortDirection, event) => {
            setOrdering(sortDirection === "asc" ? column.id : `-${column.id}`);
          }}
          onRowClicked={(row) => handleTagClicked(row)}
          fixedHeader={true}
          fixedHeaderScrollHeight={"100vh"}
        />
      </div>
    </MainContainer>
  );
}

export default Tags;
