import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import MainContainer from "../main-menu/MainContainer";
import FaceCard from "../face/FaceCard";
import { FaFilter, FaSpinner } from "react-icons/fa";
import FacesFilters from "./FacesFilters";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiFacesFilteredPagination } from "../../context/facesContext";
import { Box, CircularProgress } from "@mui/material";
import { useApiGroupsData } from "../../context/groupContext";
import { useInfiniteScroll } from "../hooks/useInfiniteScroll";
import FacesTrackingModal from "./FacesTrackingModal";
import { Flex } from "../elements/flex/Flex";
import { Face, FaceFilters, FacePage } from "../../types/Face";
import { Camera } from "../../types/Camera";
import { Person } from "../../types/Person";
import { Group } from "../../types/Group";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";

const FILTER_KEYS = [
  "name",
  "create_at_before",
  "create_at_after",
  "group_id",
  "camera_id",
  "identification_only",
  "conf",
  "spoof",
  "user_action",
  "first_seen",
  // "involuntary",
];
const PAGE_SIZE = 50;
const SORT_BY = "-create_at";

function Faces() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<FaceFilters | {}>({});
  const [readyToFetchFaces, setReadyToFetchFaces] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [trackingId, setTrackingId] = useState<string | null>(null);

  const { data: groups } = useApiGroupsData();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();
  const { data: cameras, isFetching: isFetchingCameras } = useApiCamerasData();

  const {
    data: facesData,
    isFetching: isFetchingFaces,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useApiFacesFilteredPagination(
    PAGE_SIZE,
    filters,
    SORT_BY,
    persons,
    readyToFetchFaces && !isFetchingPersons && !isFetchingCameras, // enabled
    true // attach details (face extra) to results
  );
  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage || console.log
  );

  const getFacesList = (): Face[] => {
    return (
      facesData?.pages
        ?.map((page) => (page as FacePage)?.results || [])
        .flat() || []
    );
  };

  useEffect(() => {
    setTotalResults(
      facesData?.pages?.length
        ? (facesData?.pages[0] as FacePage)?.count
        : 0 || 0
    );
  }, [facesData]);

  useEffect(() => {
    if (!searchParams) return;
    var tempFilters: any = {};
    FILTER_KEYS.forEach((key) => {
      tempFilters[key] = searchParams.get(key);
    });
    if (tempFilters.group_id && tempFilters.group_id !== "null") {
      tempFilters.group_id = tempFilters.group_id.split(",");
    }
    if (tempFilters.camera_id && tempFilters.camera_id !== "null") {
      tempFilters.camera_id = tempFilters.camera_id.split(",");
    }
    if (tempFilters.spoof && tempFilters.spoof !== "null") {
      tempFilters.spoof = tempFilters.spoof.split(",");
    }
    if (tempFilters.user_action && tempFilters.user_action !== "null") {
      tempFilters.user_action = tempFilters.user_action.split(",");
    }
    // if (tempFilters.involuntary && tempFilters.involuntary === "null") {
    //   tempFilters.involuntary = false;
    // }
    setFilters(tempFilters);

    setReadyToFetchFaces(true);
  }, [searchParams]);

  // Memoize the modal open function
  const openTrackingModal = useCallback((trackingId: string) => {
    setTrackingId(trackingId);
    setShowTrackingModal(true);
  }, []);

  // Memoize the modal close function
  const closeTrackingModal = useCallback(() => {
    setShowTrackingModal(false);
    setTrackingId(null);
  }, []);
  const isFetching = isFetchingFaces || isFetchingPersons || isFetchingCameras;
  const AppliedFilters = Object.entries(filters).filter(
    ([k, v]) => !!v && v !== "null" && k !== "first_seen"
  ).length;
  return (
    <MainContainer>
      <div style={{ color: COLORS.FontGray }}>
        <div className="d-flex align-items-center justify-content-between">
          <Flex align="center" gap="24px">
            <Flex
              gap="8px"
              align="center"
              className="pointer"
              onClick={() => setShowFilters(!showFilters)}
            >
              <Flex
                column
                style={{
                  position: "relative",
                }}
              >
                {AppliedFilters > 0 && (
                  <Flex
                    align="center"
                    justify="center"
                    title={`Currently applied ${AppliedFilters} filters`}
                    style={{
                      position: "absolute",
                      border: `1px solid ${COLORS.FontGray}`,
                      backgroundColor: COLORS.FontGray,
                      color: COLORS.Gray900,
                      borderRadius: "50%",
                      top: "-3px",
                      right: "-3px",
                      width: "12px",
                      height: "12px",
                      fontSize: "9px",
                    }}
                  >
                    <label
                      style={{
                        lineHeight: "12px",
                        fontSize: "9px",
                      }}
                    >
                      {AppliedFilters}
                    </label>
                  </Flex>
                )}
                <FaFilter color={COLORS.PrimaryLight} />
              </Flex>
              <div>{`${showFilters ? "Hide " : ""}Filters`}</div>
            </Flex>
            <Flex gap={"8px"}>
              <SwitchCheckbox
                checked={(filters as FaceFilters)?.first_seen === "true"}
                onChange={(e) => {
                  const newFilters = e.target.checked
                    ? { ...filters, first_seen: "true" }
                    : { ...filters };
                  delete newFilters.first_seen;
                  setFilters(newFilters);
                  setSearchParams({
                    ...newFilters,
                    first_seen: e.target.checked ? "true" : "false",
                  });
                }}
                size="small"
              />
              <label>Track Mode</label>
            </Flex>
          </Flex>
          {!showFilters && <div>Showing {totalResults} Faces</div>}
        </div>
        {showFilters && (
          <FacesFilters
            filters={filters}
            setFilters={setFilters}
            setSearchParams={setSearchParams}
            totalResults={totalResults}
          />
        )}
        <hr />
        <Flex column justify="around" w100 padding="16px">
          {isFetching && (
            <Box className="text-center fs-4 my-2">
              <CircularProgress color="primary" />
            </Box>
          )}
          {getFacesList()?.length === 0 && !isFetching && (
            <div className="fs-5 my-2 text-center">
              No events were found matching the search
            </div>
          )}
          <Flex gap="16px" justify="around" flexWrap>
            {getFacesList().map((face) => {
              const isUnknown =
                face.person_id === "N/A" || face.person_id === "";
              const person_id = isUnknown ? face.predicted_id : face.person_id;
              return (
                <FaceCard
                  key={`face-${face.image}`}
                  face={face || {}}
                  person={persons.find((p: Person) => p.id === person_id) || {}}
                  camera={
                    cameras.find((c: Camera) => c.id === face.camera_id) || {}
                  }
                  group={
                    groups.find((g: Group) => g.id === face.group_id) || {}
                  }
                  isUnknown={isUnknown}
                  openTrackingModal={openTrackingModal}
                />
              );
            })}
          </Flex>
          <div
            className="loader d-flex justify-content-center"
            ref={observerElemForFetchPage}
          >
            {isFetchingNextPage ? (
              <div className="d-flex flex-column justify-content-center">
                <FaSpinner className="fa-spin" />
              </div>
            ) : (
              ""
            )}
          </div>
        </Flex>
      </div>
      <FacesTrackingModal
        show={showTrackingModal}
        handleClose={closeTrackingModal}
        trackingId={trackingId}
      />
    </MainContainer>
  );
}

export default Faces;
