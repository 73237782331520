import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useApiPersonsData } from "../../context/personsContext";

const notificationsOptions = [
  { label: "known tag id", value: "known_tag_id" },
  { label: "unknown tag id", value: "unknown_tag_id" },
  { label: "tag id match person", value: "tag_id_match_person" },
  { label: "tag id_mismatch person", value: "tag_id_mismatch_person" },
  { label: "tag id unknown face", value: "tag_id_unknown_face" },
];

function TagsFilter(props) {
  const { setFilters } = props;
  const { data: persons } = useApiPersonsData();
  const [personOptions, setPersonsOptions] = React.useState([]);

  React.useEffect(() => {
    if (persons) {
      const options = persons.map((person) => ({
        label: person.person_name,
        value: person.id,
      }));
      setPersonsOptions(options);
    }
  }, [persons]);

  return (
    <div className="d-flex w-100">
      <div className="me-3">
        <TextField
          id="filter-tag-id"
          label="Search by tag ID"
          variant="standard"
          onChange={(e) =>
            setFilters((current) => ({ ...current, tag_id: e.target.value }))
          }
        />
      </div>
      <div className="col-12 col-lg-3 me-3">
        <Autocomplete
          options={notificationsOptions}
          id="filter-notification-type"
          disableCloseOnSelect
          onChange={(e, option) =>
            setFilters((current) => ({
              ...current,
              type: option?.value || "",
            }))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Notification Type"
              variant="standard"
            />
          )}
        />
      </div>
      <div className="col-12 col-lg-3">
        <Autocomplete
          options={personOptions}
          id="filter-person"
          disableCloseOnSelect
          onChange={(e, option) =>
            setFilters((current) => ({
              ...current,
              person_id: option?.value || "",
            }))
          }
          renderInput={(params) => (
            <TextField {...params} label="Person" variant="standard" />
          )}
        />
      </div>
    </div>
  );
}

export default TagsFilter;
