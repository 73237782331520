import styled from "styled-components";
import { COLORS } from "../colors";

export const Slash = styled.div`
  position: relative;
  display: inline-block;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotate(45deg);
    transform-origin: center;
    pointer-events: none;
  }

  &::after {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: ${COLORS.White};
  }
`;
