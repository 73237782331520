import React, { useState, useCallback } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaBell,
  FaDoorClosed,
  FaDoorOpen,
  FaFemale,
  FaLock,
  FaMale,
  FaRegPlayCircle,
  FaRegUser,
  FaSignInAlt,
  FaSignOutAlt,
  FaUsers,
  FaUserSecret,
} from "react-icons/fa";
import {
  BsCameraVideo,
  BsPeople,
  BsPercent,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { COLORS } from "../../assets/colors";
import VestIcon from "../../assets/icons/vest-white.png";
import HelmetIcon from "../../assets/icons/helmet-white.png";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import moment from "moment";
import "./FaceCardRecognized.css";
import { openSpoofPopup, playVideoClip } from "../../popups/opretaions";
import FaceRecognizedModal from "./FaceRecognizedModal";
import { dateDisplay } from "../../utils/helper";
import { useIsMobile } from "../../utils/hooks";
import { FaceImage } from "./FaceImage";
import { Flex } from "../elements/flex/Flex";
import { Slash } from "../../assets/icons/Slash";

function FaceCard(props) {
  const { face, person, camera, group, isUnknown, openTrackingModal } = props;
  const isMobile = useIsMobile();
  const [showDate, setShowDate] = useState(false);
  const [showFaceModal, setShowFaceModal] = useState(false);
  const isPPEEnabled = camera?.ppe_detection?.enabled;

  const faceExtra = {
    video: face.video ? face.video : face.face_extra?.video,
    spoof: face.spoof ? face.spoof : face.face_extra?.spoof,
    spoof_image: face.spoof_image
      ? face.spoof_image
      : face.face_extra?.spoof_image,
    spoof_score: face.spoof_score
      ? face.spoof_score
      : face.face_extra?.spoof_score,
    alerted: face.alerted ? face.alerted : face.face_extra?.alerted,
    entrance: face.entrance ? face.entrance : face.face_extra?.entrance,
    blocked: face.blocked ? face.blocked : face.face_extra?.blocked,
  };

  const handleTrackingModalOpen = useCallback(() => {
    openTrackingModal(face.tracking_id);
  }, [openTrackingModal, face.tracking_id]);

  return (
    <div
      className="p-1 pt-2"
      style={{
        borderRadius: "8px",
        backgroundColor: COLORS.Gray800,
        height: "254px",
        width: "176px",
        color: COLORS.FontGray,
      }}
    >
      <Modal
        show={showFaceModal}
        onHide={() => setShowFaceModal(false)}
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <FaceRecognizedModal
          close={() => setShowFaceModal(false)}
          camera={camera}
          face={face}
          person={person}
          group={group}
          isUnknown={isUnknown}
        />
      </Modal>

      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ position: "relative", cursor: "pointer" }}
        onClick={() => setShowFaceModal(true)}
      >
        <FaceImage face={face} size="140px" isTrackMode={face.first_seen ?? false} />
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Show more details</Tooltip>}
        >
          <div
            style={{
              position: "absolute",
              right: "24px",
              top: "0",
              fontSize: "16px",
            }}
          >
            <BsThreeDotsVertical />
          </div>
        </OverlayTrigger>

        {!isUnknown && person.faces && (
          <img
            className="person-image-circle"
            src={`${IMAGE_BASE_PATH}${person.faces[0]}`}
            alt={`face-person-${person.faces[0]}`}
          />
        )}
      </div>

      <div className="mx-1 mb-1 row">
        <div className="col-10">
          <div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {isUnknown
                    ? `This event has not passed the identification threshold, therefore defined as UNKNOWN`
                    : person.person_name}
                </Tooltip>
              }
            >
              {isUnknown ? (
                <div
                  className="text-truncate fw-bolder"
                  style={{
                    color: COLORS.orange,
                  }}
                >
                  UNKNOWN
                </div>
              ) : (
                <div className="text-truncate fw-bolder">
                  {person.person_name}
                </div>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{dateDisplay(new Date(face.create_at))}</Tooltip>
              }
            >
              <div
                className="text-truncate"
                onClick={() => setShowDate(!showDate)}
                style={{ fontSize: "14px", cursor: "pointer" }}
              >
                {showDate
                  ? dateDisplay(new Date(face.create_at))
                  : moment(new Date(face.create_at)).fromNow()}
              </div>
            </OverlayTrigger>
          </div>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Camera - {camera.name}</Tooltip>}
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <BsCameraVideo className="me-1" />
              <div className="text-truncate">{camera.name}</div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Confidence - {parseInt(face.grade)}%</Tooltip>}
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <BsPercent className="me-1" />
              <div className="text-truncate">
                Confidence: {parseInt(face.grade)}%
              </div>
            </div>
          </OverlayTrigger>

          {isUnknown ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Top match result is - {person.person_name}</Tooltip>
              }
            >
              <div
                className="d-flex align-items-center"
                style={{ fontSize: "12px" }}
              >
                <FaRegUser className="me-1" />
                <div className="text-truncate">{person.person_name}</div>
              </div>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Group - {group.name}</Tooltip>}
            >
              <div
                className="d-flex align-items-center fw-bold"
                style={{ fontSize: "12px", color: COLORS.GROUP[group.color] }}
              >
                <BsPeople className="me-1" />
                <div className="text-truncate">{group.name}</div>
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div className="col-1">
          {faceExtra.video && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Watch a short video of this event</Tooltip>}
            >
              <div>
                <FaRegPlayCircle
                  style={{ cursor: "pointer" }}
                  onClick={() => playVideoClip(faceExtra.video, isMobile)}
                />
              </div>
            </OverlayTrigger>
          )}
          {faceExtra.spoof === "suspicion" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  This event is not clear enough to determine whether it is an
                  attempted fraud
                </Tooltip>
              }
            >
              <div>
                <FaUserSecret
                  style={{
                    cursor:
                      faceExtra.spoof_image && faceExtra.spoof_score
                        ? "pointer"
                        : "unset",
                  }}
                  onClick={() =>
                    faceExtra.spoof_image &&
                    faceExtra.spoof_score &&
                    openSpoofPopup(faceExtra.spoof_image, faceExtra.spoof_score)
                  }
                />
              </div>
            </OverlayTrigger>
          )}
          {faceExtra.spoof === "lock" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{`Fraud attempted, score: ${parseFloat(
                  faceExtra.spoof_score
                ).toFixed(2)}`}</Tooltip>
              }
            >
              <div>
                <FaLock
                  style={{
                    cursor:
                      faceExtra.spoof_image && faceExtra.spoof_score
                        ? "pointer"
                        : "unset",
                  }}
                  onClick={() =>
                    faceExtra.spoof_image &&
                    faceExtra.spoof_score &&
                    openSpoofPopup(faceExtra.spoof_image, faceExtra.spoof_score)
                  }
                />
              </div>
            </OverlayTrigger>
          )}
          {faceExtra.alerted && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>An alert has been sent for this event</Tooltip>}
            >
              <div>
                <FaBell />
              </div>
            </OverlayTrigger>
          )}
          {face.first_seen && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  This is the first event of this person in the system, in one
                  hour. Click to see all the tracking events
                </Tooltip>
              }
            >
              <div onClick={handleTrackingModalOpen} className="pointer">
                <FaUsers />
              </div>
            </OverlayTrigger>
          )}
          {face.first_seen && isPPEEnabled && !face.track_has_helmet && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This person did not wear a helmet</Tooltip>}
            >
              <Flex style={{ position: "relative" }}>
                <img
                  alt="helmet-icon"
                  src={HelmetIcon}
                  width={16}
                  height={16}
                />

                <Slash
                  style={{
                    right: "8px",
                  }}
                />
              </Flex>
            </OverlayTrigger>
          )}
          {face.first_seen && isPPEEnabled && !face.track_has_vest && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This person did not wear a vest</Tooltip>}
            >
              <Flex style={{ position: "relative" }}>
                <img alt="vest-icon" src={VestIcon} width={16} height={16} />

                <Slash
                  style={{
                    right: "8px",
                  }}
                />
              </Flex>
            </OverlayTrigger>
          )}
          {faceExtra.entrance && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  This event activated the door opener controller
                </Tooltip>
              }
            >
              <div>
                <FaDoorOpen />
              </div>
            </OverlayTrigger>
          )}
          {faceExtra.blocked === "anti_passback" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  The door was not opened for this event as it did not comply
                  with the Anti-Passback Policy
                </Tooltip>
              }
            >
              <div>
                <FaDoorClosed />
              </div>
            </OverlayTrigger>
          )}
          {face.user_action === "clicked_enter" && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>User pressed on Enter button</Tooltip>}
            >
              <div>
                <FaSignInAlt />
              </div>
            </OverlayTrigger>
          )}
          {face.user_action === "clicked_exit" && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>User pressed on Exit button </Tooltip>}
            >
              <div>
                <FaSignOutAlt />
              </div>
            </OverlayTrigger>
          )}
          {face.gender_proba > 0.05 && face.gender === 0 && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{`Face predicted as a ${face.age}-year-old woman`}</Tooltip>
              }
            >
              <div>
                <FaFemale />
              </div>
            </OverlayTrigger>
          )}
          {face.gender_proba > 0.05 && face.gender === 1 && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{`Face predicted as a ${face.age}-year-old man`}</Tooltip>
              }
            >
              <div>
                <FaMale />
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>
    </div>
  );
}

export default FaceCard;
