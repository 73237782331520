import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { COLORS } from "./assets/colors";
import { Routes, Route, Link } from "react-router-dom";
import { Login } from "./components/login/Login";
import { createContext, useEffect, useRef, useState } from "react";
import { get, IMAGE_BASE_PATH } from "./faceit-api/FaceitApi";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import FaceitRouter from "./FaceitRouter";
import { FaPencilAlt } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "@sweetalert2/theme-dark/dark.css";
import ChangeAvatarPopup from "./components/main-menu/ChangeAvatarPopup";
import UserMenu from "./components/UserMenu";
import WarningBanner from "./components/elements/banners/WarningBanner";
import InactiveBanner from "./components/elements/banners/InactiveBanner";
import { useApiHealthCheck } from "./context/healthCheckContext";
import { useIsMobile } from "./utils/hooks";
import { UserInfo } from "./types/UserInfo";
import { WebSettings } from "./types/WebSettings";
import logo from "./assets/logo.svg";
import IconUser from "./assets/icons/user.svg";
import IconDownArrow from "./assets/icons/down-arrow.svg";
import { PopupActions } from "reactjs-popup/dist/types";

export type ContextProps = {
  userInfo: UserInfo | null;
  webSettings: WebSettings | null;
  sidebarCollapse: boolean;
  setSidebarCollapse: (value: boolean) => void;
  isMobile: boolean;
};

export const MAX_MOBILE_WIDTH_PX = 768;
export const DataContext = createContext<ContextProps>({
  userInfo: null,
  webSettings: null,
  sidebarCollapse: true,
  setSidebarCollapse: () => {},
  isMobile: false,
});

function App() {
  const [sidebarCollapse, setSidebarCollapse] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isFirstTime, setIsFirstTime] = useStateWithCallbackLazy(true);
  const [mouseHoverAvatar, setMouseHoverAvatar] = useState(false);
  const [webSettings, setWebSettings] = useState<WebSettings | null>(null);
  const { data: healthCheck } = useApiHealthCheck();
  const isMobile = useIsMobile();

  const ref = useRef<PopupActions | null>(null);
  const closePopup = () => (ref.current as any) && (ref.current as any).close();

  useEffect(() => {
    const getMe = () => {
      get(`me`).then((resp) => {
        if (resp) {
          setUserInfo(resp);
        }
      });
    };
    getMe();
  }, []);

  const getWebSettings = () => {
    get("web-settings").then((resp) => {
      if (resp.results) {
        setWebSettings(resp.results[0]);
      }
    });
  };

  if (isFirstTime) {
    setIsFirstTime(false, () => {
      getWebSettings();
    });
  }

  return (
    <div>
      <DataContext.Provider
        value={{
          userInfo,
          webSettings,
          sidebarCollapse,
          setSidebarCollapse,
          isMobile,
        }}
      >
        <div
          className="  "
          style={{
            backgroundColor: COLORS.Gray800,
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              padding: "12px",
              paddingRight: "25px",
              zIndex: "999",
              top: "0",
              left: !isMobile ? "0" : "34px",
              borderBottomRightRadius: "12px",
            }}
          >
            <Link to="/">
              <img
                src={
                  webSettings &&
                  webSettings?.logo &&
                  webSettings?.logo !== "images/logo-white.png"
                    ? `${IMAGE_BASE_PATH}${webSettings?.logo}`
                    : logo
                }
                alt="sr-logo"
                width="auto"
                height={!isMobile ? "34" : "24"}
                className="ms-2"
              />
            </Link>
          </div>
          <div
            className="text-end me-3  mt-2"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              className="me-2"
              style={{
                color: COLORS.FontGray,
                fontSize: "14px",
                lineHeight: "16px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {healthCheck.is_online === false && !isMobile && (
                <WarningBanner>Internet is offline</WarningBanner>
              )}
              {healthCheck.is_system_active === false && !isMobile && (
                <InactiveBanner>System is inactive</InactiveBanner>
              )}
              {!isMobile && userInfo && `Hello, ${userInfo?.username}`}
            </div>
            <Popup
              className="popup-small-right-menu"
              ref={ref}
              trigger={
                <img
                  className="me-3"
                  src={IconDownArrow}
                  height={"15"}
                  width={"15"}
                  alt="down-arrow"
                  style={{ cursor: "pointer" }}
                />
              }
              position="bottom center"
              on="click"
              closeOnDocumentClick
            >
              <UserMenu userInfo={userInfo} close={closePopup} />
            </Popup>

            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>Change profile image</Tooltip>}
            >
              <Popup
                modal
                position="bottom center"
                className="popup-small"
                ref={ref}
                trigger={
                  <span
                    style={{ position: "relative", cursor: "pointer" }}
                    onMouseEnter={() => setMouseHoverAvatar(true)}
                    onMouseLeave={() => setMouseHoverAvatar(false)}
                  >
                    <img
                      src={
                        userInfo?.avatar
                          ? `${IMAGE_BASE_PATH}${userInfo?.avatar}`
                          : IconUser
                      }
                      style={{
                        borderRadius: "50%",
                      }}
                      height={"40"}
                      width={"40"}
                      alt="user-avatar"
                    />
                    {mouseHoverAvatar && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                          borderBottomLeftRadius: "16px",
                          borderBottomRightRadius: "16px",
                          backgroundColor: COLORS.TransparentPrimary,
                          color: COLORS.FontGray,
                          height: "18px",
                          width: "40px",
                        }}
                      >
                        <div
                          style={{ fontSize: "12px", lineHeight: "14px" }}
                          className=" text-center"
                        >
                          <FaPencilAlt />
                        </div>
                      </div>
                    )}
                  </span>
                }
              >
                <ChangeAvatarPopup userInfo={userInfo} close={closePopup} />
              </Popup>
            </OverlayTrigger>
          </div>

          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="*" element={<FaceitRouter />}></Route>
          </Routes>
        </div>
      </DataContext.Provider>
    </div>
  );
}

export default App;
