import React from "react";
import { COLORS } from "../../assets/colors";
import Camera from "./Camera";
import { Slide } from "react-slideshow-image";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function Zone(props) {
  const { cameras, zone } = props;

  const properties = {
    duration: 5000,
    slidesToShow: 1, // calculate how many pics we can put in the screen at the same time
    slidesToScroll: 1,
    infinite: false,
    indicators: cameras.length > 1,
    arrows: cameras.length > 1,
    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          indicators: cameras.length > 4,
          arrows: cameras.length > 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          indicators: cameras.length > 3,
          arrows: cameras.length > 3,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          indicators: cameras.length > 2,
          arrows: cameras.length > 2,
        },
      },
    ],
    autoplay: false,
    prevArrow: (
      <FaChevronLeft
        className="box-shadow-hover"
        style={{ cursor: "pointer", borderRadius: "12px" }}
      />
    ),
    nextArrow: (
      <FaChevronRight
        className="box-shadow-hover"
        style={{ cursor: "pointer", borderRadius: "12px" }}
      />
    ),
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-start ps-3 text-uppercase text-truncate"
        style={{
          backgroundColor: COLORS.Gray800,
          height: "68px",
          borderRadius: "8px 8px 0 0",
        }}
      >
        {zone}
      </div>
      <div
        className="row"
        style={{
          padding: "24px",
        }}
      >
        <Slide {...properties}>
          {cameras.map((camera) => (
            <Camera key={camera.id} camera={camera} />
          ))}
        </Slide>
      </div>
    </div>
  );
}

export default Zone;
