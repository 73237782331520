import React, { useEffect, useState } from "react";
import { COLORS } from "../../assets/colors";
import { get, post } from "../../faceit-api/FaceitApi";
import DataTable, { createTheme } from "react-data-table-component";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaFileAlt, FaPlay, FaSpinner, FaStop, FaSync } from "react-icons/fa";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import LogsModal from "./LogsModal";

function ContainersTable() {
  const [containers, setContainers] = useState([]);
  const [selectedContainers, setSelectedContainers] = useState([]);
  const [isFetching, setIsFetching] = useState([]);
  const [actionInProccess, setActionInProccess] = useState(false);

  const [showLogsModal, setShowLogsModal] = useState(false);
  const [containerLogsName, setContainerLogsName] = useState(null);

  useEffect(() => {
    const fetchContainers = () => {
      setTimeout(fetchContainers, 5000);
      setIsFetching(true);
      get(`docker/containers`)
        .then((resp) => {
          if (resp.status === 1) {
            setContainers(resp.results);
          }
        })
        .finally(() => setIsFetching(false));
    };
    setTimeout(fetchContainers, 10);
  }, []);

  const getStateColor = (state) => {
    if (["exited"].includes(state)) {
      return "red";
    }
    if (["running"].includes(state)) {
      return "green";
    } else {
      return "blue";
    }
  };

  const onSelectedRowsChange = ({ selectedRows }) => {
    setSelectedContainers(selectedRows);
  };

  const triggerContiners = (action) => {
    setActionInProccess(true);
    const containers = [...new Set(selectedContainers.map((c) => c.name))];
    post("docker/action", { containers: containers, action: action })
      .then((resp) => {
        setActionInProccess(false);
        if (resp.status === 1) {
          showSuccessDialog(`Operation ${action} ended successfully`);
        } else {
          showErrorDialog(resp.message || `Failed to ${action} containers`);
        }
      })
      .finally(() => setActionInProccess(false));
  };

  const columns = [
    {
      name: "Name",
      id: "name",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => (
        <div style={{ display: "contents" }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{row.name}</Tooltip>}
          >
            <span className="text-truncate fw-bold">{row.name}</span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Logs",
      id: "logs",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => (
        <div style={{ display: "contents" }}>
          <FaFileAlt
            className="fs-4 pointer"
            style={{ color: COLORS.PrimaryLight }}
            onClick={() => {
              setContainerLogsName(row.name);
              setShowLogsModal(true);
            }}
          />
        </div>
      ),
    },
    {
      name: "State",
      id: "state",
      selector: (row) => row.state,
      sortable: false,
      cell: (row) => (
        <div
          className="p-3 w-50 text-center fw-bold m-1"
          style={{
            borderRadius: "8px",
            backgroundColor: COLORS.GROUP.LIGHT[getStateColor(row.state)],
            color: COLORS.GROUP[getStateColor(row.state)],
          }}
        >
          {row.state}
        </div>
      ),
    },
  ];

  createTheme(
    "solarized",
    {
      text: {
        primary: COLORS.Gray50,
        secondary: COLORS.Gray100,
      },
      background: {
        default: COLORS.BgBlack,
      },
      context: {
        background: "#cb4b16",
        text: COLORS.Gray50,
      },
      divider: {
        default: COLORS.Gray50,
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: COLORS.BgBlack,
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  return (
    <div>
      <Modal
        show={showLogsModal}
        onHide={() => setShowLogsModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <LogsModal
          close={() => setShowLogsModal(false)}
          containerName={containerLogsName}
        />
      </Modal>

      <div className="mt-5">
        <div
          className="p-3"
          id="system_services_buttons"
          key={actionInProccess}
        >
          <div className="btn-group" role="group">
            <button
              type="button"
              id="containers_start"
              className="d-flex align-items-center btn  sr-btn-hover"
              disabled={!selectedContainers.some((c) => c.state !== "running")}
              style={{
                color: COLORS.FontGray,
                backgroundColor: COLORS.GROUP.green,
              }}
              onClick={() => triggerContiners("start")}
            >
              {actionInProccess ? (
                <FaSpinner className="fa-spin me-2" />
              ) : (
                <FaPlay className="me-2" />
              )}
              Start
            </button>
            <button
              type="button"
              id="containers_stop"
              className="d-flex align-items-center btn sr-btn-hover"
              disabled={!selectedContainers.some((c) => c.state !== "exited")}
              style={{
                color: COLORS.FontGray,
                backgroundColor: COLORS.GROUP.red,
              }}
              onClick={() => triggerContiners("stop")}
            >
              {actionInProccess ? (
                <FaSpinner className="fa-spin me-2" />
              ) : (
                <FaStop className="me-2" />
              )}
              Stop
            </button>
            <button
              type="button"
              id="containers_restart"
              className="d-flex align-items-center btn sr-btn-hover"
              disabled={
                !selectedContainers.some((c) => c.state !== "restarting")
              }
              style={{
                color: COLORS.FontGray,
                backgroundColor: COLORS.GROUP.blue,
              }}
              onClick={() => triggerContiners("restart")}
            >
              {actionInProccess ? (
                <FaSpinner className="fa-spin me-2" />
              ) : (
                <FaSync className="me-2" />
              )}
              Restart
            </button>
          </div>
        </div>
      </div>

      <DataTable
        key={containers.length}
        columns={columns}
        data={containers}
        pagination
        highlightOnHover
        paginationTotalRows={containers.length}
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={25}
        selectableRows // add for checkbox selection
        noDataComponent={
          isFetching ? "Loading containers..." : "No containers found"
        }
        theme="solarized"
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowsHighlight={true}
      />
    </div>
  );
}

export default ContainersTable;
