import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import { deleteItem, get, post, put } from "../faceit-api/FaceitApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PEOPLE_MAX_PAGE } from "./personsContext";

async function getGroups() {
  return get(
    `groups?${new URLSearchParams({ page_size: PEOPLE_MAX_PAGE }).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}
async function updateGroup({ groupId, groupData }) {
  return put(`groups/${groupId}`, groupData);
}

async function createGroup({ groupData }) {
  return post(`groups`, groupData);
}

async function deleteGroup({ groupId }) {
  return deleteItem(`groups/${groupId}`);
}

export const useApiGroupsData = () => {
  return useQuery("getGroups", getGroups, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

export const useApiUpdateGroups = () => {
  const queryClient = useQueryClient();
  return useMutation(updateGroup, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        const newGroup = resp;
        queryClient.setQueryData("getGroups", (oldGroups) => {
          let index = oldGroups.findIndex((p) => p.id === newGroup.id);
          let newGroups = [...oldGroups];
          newGroups[index] = newGroup;
          return newGroups;
        });
        return onSuccessCallback(newGroup);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiCreateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(createGroup, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getGroups", (oldGroups) => [
          ...oldGroups,
          resp,
        ]);
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiDeleteGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteGroup, {
    onSuccess: (resp, { groupId, onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.invalidateQueries({ queryKey: ["getGroups"] });
        queryClient.invalidateQueries({ queryKey: ["getPersons"] });
        return onSuccessCallback();
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};
